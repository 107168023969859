#home {
  scroll-margin-top: 40px;
}

.introduction {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    max-width: 400px;
    line-height: 40px;
    text-align: left;
    color: $secondary;
    font-weight: 600;
    font-size: 40px;
    margin-top: 2rem;
  }

  .introText {
    // margin: 0 auto;

    p {
      width: 100%;
      max-width: 500px;
      margin-top: 20px;
      text-align: left;
      line-height: 30px;
      font-weight: 400;
      font-size: 14px;
      color: #283d5c;
    }

    .introHead {
      .marginZero {
        margin-top: 0;
      }
    }
  }

  .sale-platforms-card {
    width: 350px !important;
    border: 1px solid #e8e8e8;
    border-radius: 20px;

    &__header {
      &__title {
        display: block;
        font-size: 18px;
        font-weight: 600;
        margin-top: 10px;
        color: #283d5c;
      }

      &__border-text {
        display: flex;
        font-size: 14px;
        font-weight: 400;
        margin-top: 10px;
        color: #283d5c;

        &::before,
        &::after {
          content: "";
          flex-grow: 1;
          background: #D8D8D8;
          height: 1px;
          position: relative;
          top: 0.7em;
          // margin: 0 10px;
        }
      }
    }

    &__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      padding: 10px;

      &__box {
        border: 1px solid #e5e5e5;
        border-radius: 16px;
        padding: 10px;
        display: flex;
        height: 40px;
      }
    }
  }

  .introImg {
    display: flex;
    justify-content: center;

    img {
      margin-top: 1rem;
      width: 100%;
      height: auto;
    }
  }
}

.intro-list {
  list-style-type: none;
  padding: 0;
  text-align: left;
  margin-top: 30px;
}

.intro-boxes {
  margin-top: 30px;
  display: flex;

  &__title {
    display: block;
    text-align: left;
    font-size: 14px;
  }

  .intro-box-1 {
    width: 45%;
  }
  .intro-box-2 {
    width: 55%;
  }

  li {
    list-style-type: none;
    padding: 0;
    text-align: left;
    font-size: 12px;

    img {
      margin-right: 5px;
    }
  }
}

// for mobile
@media screen and (max-width: 768px) {
  .sale-platforms-card {
    margin-top: 40px !important;
  }
  .intro-list {
    font-size: 12px;
  }
  .intro-boxes {
    display: flex;
    flex-direction: column;
    &__title {
      font-size: 17px;
      margin-bottom: 5px;
      margin-top: 10px;
    }
    .intro-box-1 {
      width: 100%;
    }
    .intro-box-2 {
      width: 100%;
    }
  }
}

@media (min-width: 812px) {
  .introduction {
    .introText {
      display: flex;
      align-items: center;
      padding: 0 1rem;

      .introHead {
        margin-right: 1rem;
      }
    }


    .introImg {
      img {
        width: 85%;
        height: auto;
      }
    }
  }
}

@media (min-width: 1200px) {
  .introduction {
    margin: 2rem auto;
    width: 1140px;
    flex-direction: row;
    justify-content: space-between;

    .introText {
      display: block;
    }

    .introImg {
      img {
        width: 80%;
        height: auto;
      }
    }
  }
}

/* @media (min-width: 375px) {
  
}
@media (min-width: 812px) {
  
}

@media (min-width: 1200px) {

}
 */



.border-text {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  color: #283d5c;

  &::before,
  &::after {
    content: "";
    flex-grow: 1;
    background: #D8D8D8;
    height: 1px;
    position: relative;
    top: 0.7em;
    // margin: 0 10px;
  }
}

.seeDetails {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  width: 100%;
  text-align: center;
  a {
    display: block;
    // width: 83%;
    transition: all 0.4s;
    font-size: 14px;
    background-image: linear-gradient(to right,
        #5340ff 0,
        #ff005e 51%,
        #5340ff 100%);
    background-size: 200% auto;
    color: white;
    border-radius: 3rem;
    padding: 10px 20px;
    margin: 0 10px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 6px;
      font-size: 1.6rem;
      color: white;
    }
  }
  a:hover {
    transition: all 0.4s;
    background-position: right center;
    color: white;
  }
}