// IntroButtons component
.introBtn {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  height: 40px;
  .btn {
    display: flex;
    height: 14px;
    margin-right: 0.5rem;
    align-items: center;
    transition: all 0.4s;
    color: #000659;
    background-color: #FFFFFF;
    // box-shadow: 3px 7px 15px rgba(69, 160, 239, 0.66);
    border: 1px solid #293C5C50;
    background-size: 200% auto;
    // border: none;
    border-radius: 3rem;
    padding: 1rem;
    font-size: 11px;
    font-weight: 400;
    cursor: pointer;
    a {
      color: white;
    }
  }

  .play {
    margin-right: 0.5rem;
    background: none;
    border: none;
    cursor: pointer;
    .playIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #000159;
      border-radius: 50%;
      padding: 1rem;
      svg {
        font-size: 20px;
        margin: 0;
        color: $primary;
      }
    }
  }
  .play:before {
    content: '';
    display: block;
    position: absolute;
    margin-top: 3px;
    margin-left: 3px;
    width: 44px;
    height: 44px;
    border: 2px solid #000159;
    border-radius: 50%;
    animation: zoom 0.8s linear 0.4s infinite;
  }

  .btn:hover {
    transition: all 0.4s;
    background-position: right center;
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.btn {
  svg {
    font-size: 18px;
    margin-left: 5px;
  }
}

//Youtube iframe
.frame {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #8a8a8ad2;
  outline: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 999;

  svg {
    font-size: 42px;
    position: absolute;
    top: 8px;
    right: 20px;
    cursor: pointer;
  }
  svg:hover {
    color: #eb0b34;
  }
}

@media (min-width: 812px) {
  .introBtn {
    .btn {
      margin-right: 0.5rem;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

@media (min-width: 1200px) {
}
