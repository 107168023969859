.partners {
  width: 350px;
  margin: 0 auto;
  h3 {
    font-size: 28px;
    color: $primary;
    font-weight: 600;
    margin-top: 2rem;
  }
  .line {
    margin: 0.7rem auto;
  }

  .container {
    padding: 10px;
    margin: 2rem auto;
  }

  .image-wrapper {
    width: 180px;
    height: auto;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin: auto;
      width: 100%;
    }
  }
}
@media (min-width: 812px) {
  .partners {
    width: 700px;
    margin: 0 auto;
  }
  .image-wrapper {
    width: 190px;
    height: auto;
  }
}
@media (min-width: 1200px) {
  .partners {
    width: 1140px;
    margin: 0 auto;
  }
}

.partners-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;

  &__card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110px;
    width: 270px;
    background-color: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}
@media (max-width: 768px) {
  .partners-container {
    &__card {
      width: 100% !important;
    }
  }
}