#howItWorks {
  scroll-margin-top: 110px;
}

.howItWorks {
  margin: 2rem auto;
}
//Why promodex section
.why {
  margin: 2rem auto;
  .whyContent {
    margin: 0 auto;
    padding: 0 15px;
    color: $primary;
    .whyHeader {
      font-size: 36px;
      font-weight: 600;
      padding-top: 16px;
    }
    .whySubHeader {
      font-size: 22px;
      font-weight: 500;
      margin: 10px auto;
    }
    .whyListBox {
      ul {
        .whyListItem {
          font-size: 14px;
          text-align: left;
          display: flex;
        }
      }
      h6 {
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 32px;
          margin-right: 1rem;
          color: $third;
        }
      }
    }
  }
  .whyImg {
    padding: 0 15px;
    margin-top: 140px;
    img {
      width: 90%;
      height: auto;
    }
  }
}

//Primary Function Section

.primaryFuncContainer {
  margin-top: 30px;
  color: $primary;
  .primaryFuncHead {
    h3 {
      font-size: 36px;
      font-weight: 600;
      padding-top: 16px;
    }
    .primaryFuncGif {
      img {
        width: 100%;
        height: auto;
        margin-top: -2rem;
      }
    }
  }
  .primaryFuncList {
    padding: 0 1rem;
    h6 {
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 32px;
        margin-right: 1rem;
        color: $third;
      }
    }
    ul {
      text-align: left;
      .whyListItem {
        display: flex;
        font-size: 14px;
        margin: 1rem 0.5rem;
      }
    }
  }
}

@media (min-width: 812px) {
  .why {
    .whyContent {
      .whyHeader {
        font-size: 42px;
      }
      .whyListBox {
        display: flex;
        justify-content: center;
        .listPart {
          max-width: 340px;
        }
      }
    }
    .whyImg {
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .primaryFuncContainer {
    .primaryFuncHead {
      h3 {
        font-size: 42px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .why {
    width: 1140px;
    display: flex;
  }
  .primaryFuncContainer {
    width: 1140px;
    margin: 1rem auto;
  }
  .primaryFuncList {
    display: flex;
    .equalFlexBox {
      width: 380px;
    }
  }
}

@media (max-width: 768px) {
  .whyImg {
    margin-top: 40px !important;
  }
}
